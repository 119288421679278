import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import {
	AppBar,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Paper,
	TextField,
	Toolbar,
	Tooltip,
	Typography,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import BasicLayout from "../../lib/components/BasicLayout";
import SortableTable from "../../lib/components/SortableTable";
import User from "../../models/User";
import { useAppDispatch } from "../../store";
import usersAsyncActions from "../../store/actions/users.action";
import RequestManager from "../../store/request-manager";
import { useRequestState, useUsersState } from "../../store/selectors";
import { API } from "lib/util";
import toast from "react-hot-toast";

export interface ICSVResource {
	csv: string;
}

interface Props {}

const UsersScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const usersState = useUsersState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [query, setQuery] = React.useState<string>("");

	const data = React.useMemo<User[]>(() => {
		if (query.trim().length <= 0) {
			return usersState.list;
		}

		return usersState.list.filter((element) =>
			`${element.email}${element.fullName}`
				.trim()
				.toLowerCase()
				.includes(query)
		);
	}, [usersState.list, query]);

	const csvFetch = async () => {
		try {
			const { data } = await API.get<any, ICSVResource>("/users/csv");
			const blob = new Blob([data.csv], { type: "text/csv" });
			const url = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = "download.csv";
			a.click();
			toast.success("Users are being downloaded.");
		} catch (error) {
			toast.error("Error downloading the users, try again");
		}
	};

	const handleRefresh = React.useCallback(() => {
		dispatch(usersAsyncActions.index());
	}, [dispatch]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(usersAsyncActions.index.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(usersAsyncActions.index.typePrefix)) {
			RM.consume(usersAsyncActions.index.typePrefix);
		}
	}, [usersState, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(usersAsyncActions.index());
	}, []);

	if (isLoading) {
		return (
			<BasicLayout
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CircularProgress />
			</BasicLayout>
		);
	}

	return (
		<BasicLayout>
			<Paper sx={{ margin: "auto", overflow: "hidden" }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
				>
					<Toolbar>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<SearchIcon
									color="inherit"
									sx={{ display: "block" }}
								/>
							</Grid>
							<Grid item xs>
								<TextField
									fullWidth
									placeholder="Search by email address or name"
									InputProps={{
										disableUnderline: true,
										sx: { fontSize: "default" },
									}}
									value={query}
									onChange={(e) => setQuery(e.target.value)}
									variant="standard"
								/>
							</Grid>
							<Grid item>
								<Button
									onClick={csvFetch}
									variant="contained"
									sx={{ mr: 1 }}
								>
									Download all
								</Button>
								<Button
									onClick={() =>
										history.push("/users/create")
									}
									variant="contained"
									sx={{ mr: 1 }}
								>
									Add user
								</Button>
								<Tooltip title="Reload">
									<IconButton onClick={handleRefresh}>
										<RefreshIcon
											color="inherit"
											sx={{ display: "block" }}
										/>
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				{data.length === 0 && (
					<Typography
						sx={{ my: 5, mx: 2 }}
						color="text.secondary"
						align="center"
					>
						No users found.
					</Typography>
				)}
				{data.length > 0 && (
					<SortableTable
						onRowClick={(id: string) =>
							history.push(`/users/${id}/update`)
						}
						onDeleteClick={(ids: string[]) =>
							ids.forEach((id) =>
								dispatch(usersAsyncActions.destroy({ id }))
							)
						}
						title={"Users"}
						data={data}
						header={[
							{
								id: "fullName",
								label: "Name",
								numeric: false,
								disablePadding: true,
							},
							{
								id: "email",
								label: "E-Mail",
								numeric: false,
								disablePadding: false,
							},
							{
								id: "createdAt",
								label: "Created At",
								numeric: false,
								dateFormat: "ISO",
								disablePadding: false,
							},
							{
								id: "cardsAmount",
								label: "Cards #",
								numeric: true,
								disablePadding: false,
							},
						]}
					/>
				)}
			</Paper>
		</BasicLayout>
	);
};

export default UsersScreen;
